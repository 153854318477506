import React from "react";

import PageWrapper from "../components/PageWrapper";

const Terms = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-center",
          headerButton: (
            <form>
              <button
                formaction="mailto:support@appshand.com"
                className="btn btn btn-dodger-blue-2 header-btn rounded-5"
              >
                Contact Us
              </button>
            </form>
          ),
          footerStyle: "style3",
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Terms of Use</h2>
                  <p className="font-size-7 mb-0">
                    This EULA forms a binding legal agreement between you and
                    AppsHand as of the date you download the Mobile App.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8">
                    <p className="font-size-5 heading-default-color mb-0">
                      Please read this Mobile Application End User License
                      Agreement (“EULA”) carefully before downloading or using
                      the AppsHand Inc. (“AppsHand”) mobile application (“Mobile
                      App”), which allows You to access AppsHand’s
                      internet-delivered service (“Subscription Service”) from
                      Your mobile device. This EULA forms a binding legal
                      agreement between you (and any other entity on whose
                      behalf you accept these terms) (collectively “You” or
                      “Your”) and AppsHand (each separately a “Party” and
                      collectively the “Parties”) as of the date you download
                      the Mobile App. Your use of the Mobile App is subject to
                      this EULA and Your use of the Subscription Service will
                      remain subject to the existing agreement governing such
                      use (the “Subscription Agreement”). With respect to the
                      use of the Mobile App, and to the extent the Subscription
                      Agreement conflicts with this EULA, the terms of this EULA
                      will govern and control solely with respect to use of the
                      Mobile App.
                    </p>
                    <div className="mt-9 mt-lg-11">
                      <h5 className="font-size-7 mb-7">License</h5>
                      <p className="font-size-5 heading-default-color">
                        AppsHand grants You a revocable, non-exclusive,
                        non-transferable, limited license to download, install,
                        and use the Mobile App for Your personal and internal
                        business purposes strictly in accordance with this EULA
                        and the Subscription Agreement.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Your Account</h5>
                      <p className="font-size-5 heading-default-color mb-9">
                        Your use of the Mobile App requires that You have an
                        account with AppsHand and agree to the terms of
                        theSubscription Agreement.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Changes to this EULA</h5>
                      <p className="font-size-5 heading-default-color">
                        AppsHand reserves the right to modify this EULA at any
                        time and for any reason. AppsHand will post the most
                        current version of this EULA at
                        https://www.AppsHand.com/legal/mobile-eula. If AppsHand
                        makes material changes to this EULA, You will receive
                        notification via the Mobile App. Notwithstanding the
                        foregoing, You are responsible for complying with the
                        updated terms posted online at AppsHand’s website even
                        if these updated terms appear online at AppsHand’s
                        website before being posted on the Mobile App. Your
                        continued use of the Mobile App after AppsHand publishes
                        notice of changes to this EULA indicates Your consent to
                        the updated terms.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">
                        No Included Maintenance and Support
                      </h5>
                      <p className="font-size-5 heading-default-color">
                        AppsHand may deploy changes, updates, or enhancements to
                        the Mobile App at any time. AppsHand may provide
                        maintenance and support for the Mobile App, but has no
                        obligation whatsoever to furnish such services to You
                        and may terminate such services at any time without
                        notice. You acknowledge that neither Apple (for iOS
                        Mobile App) has an obligation to furnish any maintenance
                        or support services in connection with the Mobile App.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Acceptable Use</h5>
                      <p className="font-size-5 heading-default-color">
                        You agree that You will not use or encourage others to
                        use the Mobile App or the Subscription Service as
                        accessed through the Mobile App in a way that could harm
                        or impair others’ use of the Mobile App or the
                        Subscription Service. Your use of the Subscription
                        Service and the Mobile App is governed by the Acceptable
                        Use Policy (a current version of which can be found at
                        https://www.AppsHand.com/legal/acceptable-use-policy).
                        You also agree not to violate the usage limits or
                        controls set forth by: (a) the App Store Terms of
                        Service, for iOS users accessing the Mobile App on an
                        Apple product.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Privacy</h5>
                      <p className="font-size-5 heading-default-color">
                        In order to operate and provide the Subscription Service
                        and the Mobile App, AppsHand may collect certain
                        information about You, including technical and telemetry
                        data related to your use of the Mobile App. We use third
                        party service providers to help us collect and analyze
                        this data, including Google Analytics. AppsHand uses and
                        protects that information in accordance with the
                        AppsHand Privacy Notice (a current version of which can
                        be found at www.AppsHand.com/legal/privacy-offerings).
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">
                        Consent to Electronic Communications and Solicitation
                      </h5>
                      <p className="font-size-5 heading-default-color">
                        By downloading the Mobile App, You authorize AppsHand to
                        send You (including via email and push notifications)
                        information regarding the Subscription Service and the
                        Mobile App, such as: (a) notices about Your use of the
                        Subscription Service and the Mobile App, including
                        notices of violations of use; (b) updates to the
                        Subscription Service and Mobile App and new features or
                        products; and (c) promotional information and materials
                        regarding AppsHand's products and services. You can
                        review Your account notification settings and adjust
                        Your messaging preferences, including opting-in to
                        additional messages or unsubscribing to certain
                        messaging through the “Push Notifications” section of
                        the Mobile App settings.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">No Warranty</h5>
                      <p className="font-size-5 heading-default-color">
                        YOUR USE OF THE MOBILE APP IS AT YOUR SOLE RISK. THE
                        MOBILE APP IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
                        BASIS. AppsHand EXPRESSLY DISCLAIMS ALL WARRANTIES OF
                        ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE
                        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                      </p>
                      <p className="font-size-5 heading-default-color">
                        The Mobile App is only available for supported devices
                        and might not work on every device. Determining whether
                        Your device is a supported or compatible device for use
                        of the Mobile App is solely Your responsibility, and
                        downloading the Mobile App is done at Your own risk.
                        AppsHand does not represent or warrant that the Mobile
                        App and Your device are compatible or that the Mobile
                        App will work on Your device.
                      </p>
                      <p className="font-size-5 heading-default-color">
                        iOS Application. In the event of AppsHand’s failure to
                        conform to any applicable warranty, You may notify
                        Apple, and Apple will refund the purchase price for the
                        Mobile App. TO THE MAXIMUM EXTENT PERMITTED BY
                        APPLICABLE LAW, APPLE WILL HAVE NO OTHER WARRANTY
                        OBLIGATION WHATSOEVER WITH RESPECT TO (A) THE MOBILE
                        APP, AND (B) ANY OTHER CLAIMS, LOSSES, LIABILITIES,
                        DAMAGES, COSTS, OR EXPENSES ATTRIBUTABLE TO ANY FAILURE
                        TO CONFORM TO ANY WARRANTY.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">
                        Suspension and Termination of the Mobile App
                      </h5>
                      <p className="font-size-5 heading-default-color">
                        AppsHand reserves the right to suspend or terminate Your
                        access to the Mobile App at any time based on the status
                        of Your account under the Subscription Agreement. You
                        understand that if Your account is suspended or
                        terminated, You may no longer have access to the content
                        that is stored within the Subscription Service.{" "}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">
                        Intellectual Property Rights
                      </h5>
                      <p className="font-size-5 heading-default-color">
                        In the event of a third party claim that the Mobile App,
                        or Your possession and use of the Mobile App, infringes
                        third party’s intellectual property rights, AppsHand
                        will be solely responsible for the investigation,
                        defense, settlement and discharge of any such
                        intellectual property infringement claim.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Legal Compliance</h5>
                      <p className="font-size-5 heading-default-color">
                        You represent and warrant that: (a) You are not located
                        in a country that is subject to a United States
                        Government embargo, or that is on Title 15, Part 740
                        Supplement 1 Country Group E of the United States Code
                        of Federal Regulations; (b) You are not located in a
                        country that has been designated by the United States
                        Government as a “terrorist supporting” country; and (c)
                        You are not listed on any U.S. Government list of
                        prohibited or restricted parties. You further agree not
                        to transport the Mobile App to or use the Mobile App in
                        any such country.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Governing Law</h5>
                      <p className="font-size-5 heading-default-color">
                        This EULA shall be governed by and construed in
                        accordance with the laws governing Your Subscription
                        Agreement.
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">Contact Information</h5>
                      <p className="font-size-5 heading-default-color">
                        If You have any questions regarding this EULA, please
                        contact AppsHand by email at support@appshand.com
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h5 className="font-size-7 mb-7">
                        Third Party Beneficiaries
                      </h5>
                      <p className="font-size-5 heading-default-color">
                        This EULA is executed between You and AppsHand and not
                        between you and any other party, including Apple for iOS
                        users. You agree that any claims brought by You arising
                        out of this EULA or Your use of the Mobile App will not
                        be made against Apple or Google, as applicable.
                        Notwithstanding the foregoing, upon Your acceptance of
                        this EULA, allows Apple or Google, as applicable, to
                        enforce this EULA against You as a third party
                        beneficiary thereof. AppsHand is not responsible for any
                        applicable third-party agreement between You and any
                        third-party, including your wireless provider.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Terms;
